; (function ($) {
  'use strict';

  const theme = {

    init: () => {
      theme.carouselmaterial();
      theme.carouselHeader();
    },


    /**
     * Content carousel with extensive options to control behaviour and appearance
     * @memberof theme
     * @method carousel
     * @requires https://github.com/ganlanyuan/tiny-slider
    */
    carouselmaterial: () => {
      if ($('#es-carousel').length === 0) return;

      var slider = tns({
        "container": "#es-carousel",
        "controlsContainer": "#tns-controls",
        "items": 1,
        //   controlsText: ['<i class="ic-arrow-left"></i>', '<i class="ic-arrow-right"></i>'],
        // "prevButton":"#tns-prevBtn",
        // "nextButton":"#tns-nextBtn",
        // controlsText: ['<i class="cxi-arrow-left"></i>', '<i class="cxi-arrow-right"></i>'],

        "arrowKeys": true,
        "nav": false,
        "controls": true,
        "mouseDrag": true,
        "lazyload": true,
        "responsive": {
          480: {
            "edgePadding": 10,
            "gutter": 10,
            "items": 2
          },
          576: {
            "edgePadding": 20,
            "gutter": 20,
          },
          768: {
            "items": 3
          },
          992: {
            "gutter": 40
          },
          1200: {
            "items": 4
          },
          1400: {
            "gutter": 50
          }
        },
        "swipeAngle": false,
        "speed": 400,
        "nonce": true
      });
    },

    carouselHeader: () => {
      if ($('#es-carousel-header').length === 0) return;

      var slider = tns({
        "container": "#es-carousel-header",
        // "controls": false,
        "controlsContainer": "#customize-controls",
        // "nav": false,
        "navContainer": "#customize-nav",
        "items": 1,
        "autoplay": true,
        "autoplayTimeout": 7000,
        "autoplayHoverPause": false, // met le slider en pause quand mouse hover
        "autoplayButtonOutput": false, // desactive l'affichage automatique d'un boutton pause
        "arrowKeys": true,
        "mouseDrag": true,
        "lazyload": true,
        "swipeAngle": false,
        "speed": 600,
        "nonce": true
      });
    },
  }

  /**
   * Init theme core
  */

  theme.init();

})(jQuery);
